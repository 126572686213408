'use client'

import clsx from 'clsx'
import styles from './spinner.module.css'

interface Props {
  lightTheme?: boolean
}

export function Spinner({ lightTheme = false }: Props) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.loader}>
        <svg className={styles.circular} viewBox="25 25 50 50" role="status">
          <circle
            className={clsx(styles.path, !lightTheme && styles.pathDark)}
            cx="50"
            cy="50"
            r="20"
            fill="none"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </svg>
      </div>
    </div>
  )
}
