'use client'

import { Spinner } from '@/components/common/spinner'
import { useEffect } from 'react'
import { identityServerSignIn } from './actions'

export default function SignIn() {
  useEffect(() => {
    identityServerSignIn()
  }, [])

  return <Spinner />
}
